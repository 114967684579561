import React, { useState } from 'react';

import lang from '@utils/lang';

import MainLayout from '@organisms/main/mainLayout';
import Header from '@organisms/main/header';
import Footer from '@organisms/main/footer';

import './cgu.scss';

const CGU = () => {
    const { _string, _markDown } = lang;

    const [menuOpen, setMenuOpen] = useState(false);
    const [subListOpen, setSubListOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleSubList = (e) => {
        e.preventDefault();
        setSubListOpen(!subListOpen);
    };

    return (
        <MainLayout title={_string('cgu_titleTab')} socials={_string('cgu_socials')} classTitle="cgu" footer={false}>
            <div className="primary_block">
                <div className="slidable">
                    <Header
                        color="white"
                        menuOpen={menuOpen}
                        toggleMenu={toggleMenu}
                        toggleSubList={toggleSubList}
                        setSubListOpen={setSubListOpen}
                        isSubListOpen={subListOpen}
                    />
                </div>
                <div className="primary_block__content">
                    {_markDown('cgu')}
                </div>
                <Footer />
            </div>
        </MainLayout>
    );
};

export default CGU;
